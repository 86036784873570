const D = require("./dom.js").DomUtl;

class XchainCheck {
    constructor(app, status_div, result_div, asset_name) {
        this.app = app;
        this.status_div = status_div;
        this.result_div = result_div;
        this.asset_name = asset_name;

        this.asset_info = null;
        this.asset_holders = null;
        this.kvlt_holders = null;
        this.file_size_check = null;
    }

    postCheckMessage(msg) {
        D.deleteChildren(this.status_div);
        D.textParagraph(this.status_div, msg);
    }

    sendRequest(request_url, func) {
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = func;
        xhttp.open("GET", request_url, true);
        xhttp.send();
    }

    //////////////////////////////////////////////////////////////////////////
    // Kvlt Holders
    //////////////////////////////////////////////////////////////////////////

    fetchKvltHoldersCb(ready_state, status, response_text) {
        if (ready_state == 4) {
            if (status == 200) {
                console.log(response_text);
                var r = JSON.parse(response_text);
                if ('error' in r) {
                    this.postCheckMessage("Xchain says: " + r['error']);
                    this.app.drawResetCheckOkButton()
                    return;
                }
                this.kvlt_holders = r['data'];
                this.doValidate();
            } else {
                this.postCheckMessage(
                    "Whoops! error from Xchain " + response_text);
                this.app.drawResetCheckOkButton()
            }
        }
    }

    fetchKvltHolders() {
        this.postCheckMessage("Fetching from XChain. Stand By.... (3/3)")
        var check = this;
        this.sendRequest("https://dogeparty.xchain.io/api/holders/KVLT",
            function() {
                check.fetchKvltHoldersCb(this.readyState, this.status,
                                         this.responseText);
            });
    }

    //////////////////////////////////////////////////////////////////////////
    // Asset Holders
    //////////////////////////////////////////////////////////////////////////

    fetchAssetHoldersCb(ready_state, status, response_text) {
        if (ready_state == 4) {
            if (status == 200) {
                console.log(response_text);
                var r = JSON.parse(response_text);
                if ('error' in r) {
                    this.postCheckMessage("Xchain says: " + r['error']);
                    this.app.drawResetCheckOkButton()
                    return;
                }
                this.asset_holders = r['data'];
                this.fetchKvltHolders();
            } else {
                this.postCheckMessage("Whoops! error from Xchain " +
                    response_text);
                this.app.drawResetCheckOkButton()
            }
        }
    }

    fetchAssetHolders() {
        this.postCheckMessage("Fetching from XChain. Stand By.... (2/3)")
        var check = this;
        this.sendRequest("https://dogeparty.xchain.io/api/holders/" +
                         this.asset_name,
            function() {
                check.fetchAssetHoldersCb(this.readyState, this.status,
                                          this.responseText);
            });
    }

    //////////////////////////////////////////////////////////////////////////
    // Asset Info
    //////////////////////////////////////////////////////////////////////////

    fetchAssetInfoCb(ready_state, status, response_text) {
        if (ready_state == 4) {
            if (status == 200) {
                console.log(response_text);
                var r = JSON.parse(response_text);
                if ('error' in r) {
                    this.postCheckMessage("Xchain says: " + r['error']);
                    this.app.drawResetCheckOkButton();
                    return;
                }
                this.asset_info = r;
                this.fetchAssetHolders();
            } else {
                this.postCheckMessage(
                    "Whoops! error from Xchain " + response_text);
                this.app.drawResetCheckOkButton();
            }
        }
    }

    fetchAssetInfo() {
        this.postCheckMessage("Fetching from XChain. Stand By.... (1/3)")
        var check = this;
        this.sendRequest("https://dogeparty.xchain.io/api/asset/" +
                         this.asset_name,
            function() {
                check.fetchAssetInfoCb(this.readyState, this.status,
                                       this.responseText);
            });
    }


    //////////////////////////////////////////////////////////////////////////
    // run check
    //////////////////////////////////////////////////////////////////////////

    hasCard(address) {
        var holders = this.asset_holders;
        for (var i = 0; i < holders.length; i++) {
            if (holders[i]['address'] == address) {
                return true;
            }
        }
        return false;
    }

    checkDistribution(div) {
        var has_card = 0;
        var kvlt_members = this.kvlt_holders;
        D.emptyDiv(div, "h-5");
        D.textParagraph(div, "Distribution to " + kvlt_members.length + " Kvlt members:", "font-bold");
        D.emptyDiv(div, "h-5");
        D.textParagraph(div, "Note: Leeway is granted for when KVLT cards change hands " +
                        "and enter/exit escrow for dex and dispenser contracts or when a " +
                        "Kvlt member moves a card you have given them. " +
                        "Make an honest effort. Dishonest efforts will be very bad " +
                        "for your soul.");
        D.emptyDiv(div, "h-5");

        function shuffle(array) {
            array.sort(() => Math.random() - 0.5);
        }

        shuffle(kvlt_members);
        for (var i = 0; i < kvlt_members.length; i++) {
            var a = kvlt_members[i]['address'];
            var has_card = this.hasCard(a);
            this.validateMembersResultRow(div, a, has_card);
        }
    }

    validateMembersResultRow(div, label, result) {
        var r = D.emptyDiv(div, "flex justify-between");
        D.textParagraph(r, label, "text-left");
        if (result) {
            D.textParagraph(r, "OK", "text-right text-green-400 font-bold");
        } else {
            D.textParagraph(r, "PROBLEM", "text-right text-red-400 font-bold");
        }

    }

    validateResultRow(div, label, result) {
        var r = D.emptyDiv(div, "flex justify-between");
        D.textParagraph(r, label + ":", "text-left");
        if (result) {
            D.textParagraph(r, "OK", "text-right text-green-400 font-bold");
        } else {
            D.textParagraph(r, "PROBLEM", "text-right text-red-400 font-bold");
        }
    }

    checkInProgress(div, label) {
        D.deleteChildren(div);
        this.file_size_check = div;
        var r = D.emptyDiv(div, "flex justify-between");
        D.textParagraph(r, label + ":", "text-left");
        D.textParagraph(r, "IN PROGRESS", "text-right text-yellow-400 font-bold");
    }

    checkFinished(label, result) {
        var div = this.file_size_check;
        D.deleteChildren(div);
        var r = D.emptyDiv(div, "flex justify-between");
        D.textParagraph(r, label + ":", "text-left");
        if (result) {
            D.textParagraph(r, "OK", "text-right text-green-400 font-bold");
        } else {
            D.textParagraph(r, "PROBLEM", "text-right text-red-400 font-bold");
        }
    }

    validateBlabRow(div, label, text) {
        var r = D.emptyDiv(div, "flex justify-between");
        D.textParagraph(r, label + ":", "text-left");
        D.textParagraph(r, text, "text-right font-bold");
    }

    testImgurUrl(div, asset_ref, extension) {
        var url = "https://i.imgur.com/" + asset_ref + "." + extension;
        var c = D.emptyDiv(div, "flex flex-col");
        var d = D.emptyDiv(c, "flex flex-row justify-center");

        var fetch_status = D.textParagraph(d, "fetching art....");

        var img = new Image();
        var checker = this;

        img.onload = (function() {
            fetch_status.innerHTML = "";
            D.emptyDiv(d, "h-3");
            this.validateResultRow(c, "Art fetches from img", true);
            var width_ok = (img.naturalWidth == 400) || (img.naturalWidth == 800);
            this.validateResultRow(c, "With is 400px or 800px", width_ok);
            var height_ok = (img.naturalHeight == 560) || (img.naturalHeight == 1120);
            this.validateResultRow(c, "Height is 560px or 1120px", height_ok);
            var vert_card = ((img.naturalWidth == 400 && img.naturalHeight == 560) ||
                             (img.naturalWidth == 800 && img.naturalHeight == 1120));
            this.validateResultRow(c, "Vertical Rectangle", vert_card);
            // TODO file size
            var file_size_div = D.emptyDiv(c);
            this.checkFileSize(file_size_div, url);
            checker.checkDistribution(div);
        }).bind(this);
        img.onerror = (function() {
            this.validateResultRow(d, "Art loads", false);
        }).bind(this);
        img.width = 400;
        img.src = url;

        d.appendChild(img);
    }

    validExtension(ext) {
        if (ext == "gif") {
            return true;
        } else if (ext == "GIF") {
            return true;
        } else if (ext == "jpg") {
            return true;
        } else if (ext == "JPG") {
            return true;
        } else if (ext == "JPEG") {
            return true;
        } else if (ext == "jpeg") {
            return true;
        } else if (ext == "png") {
            return true;
        } else if (ext == "PNG") {
            return true;
        }
        return false;
    }

    doValidate() {
        console.log("do validation and finish here!");
        D.deleteChildren(this.status_div);
        D.deleteChildren(this.result_div);

        var center = D.emptyDiv(this.result_div, "flex justify-center");
        var col = D.emptyDiv(center, "flex flex-col justify-center w-1/2");

        D.textParagraph(col, "Asset properties for " + this.asset_name + ":", "font-bold");
        D.emptyDiv(col, "h-5");
        this.validateResultRow(col, "Is a named asset", true);
        this.validateResultRow(col, "Is not a subasset", true);
        var locked = this.asset_info['locked'];
        this.validateResultRow(col, "Is locked", locked);
        var nondivisible = this.asset_info['divisible'] != true;
        this.validateResultRow(col, "Is non-divisible", nondivisible);

        var issuance = (this.asset_info['supply'] >= 100 &&
                        this.asset_info['supply'] <= 125);

        this.validateResultRow(col, "Issuance min 100 max 125", issuance);
        D.emptyDiv(col, "h-5");

        // Description string
        D.textParagraph(col, "Description for " + this.asset_name +":", "font-bold");
        D.emptyDiv(col, "h-5");

        var description = this.asset_info['description'];
        description = description.trim()

        var presemi = description.split(";")[0];
        this.validateBlabRow(col, "Found before semicolon", presemi);
        var dotsplit = presemi.split(".");
        if (dotsplit.length == 1) {
            console.log("1");
            this.validateResultRow(col, "Parsed imgur reference", false);
            return;
        }
        var postdot = dotsplit[1];
        var predot = dotsplit[0];
        var slashsplit = predot.split("/");
        if (slashsplit.length == 1) {
            console.log("2");
            this.validateResultRow(col, "Parsed imgur reference", false);
            return;
        }
        var preslash = slashsplit[0];
        var postslash = slashsplit[1];
        this.validateBlabRow(col, "Location", preslash);
        if (preslash != "imgur") {
            console.log("3");
            this.validateResultRow(col, "Parsed imgur reference", false);
            return;
        }
        this.validateBlabRow(col, "Imgur asset ref", postslash);
        this.validateBlabRow(col, "File extension",  postdot);
        if (! this.validExtension(postdot)) {
            this.validateResultRow(col, "Valid file extension", false);
        }
        this.validateResultRow(col, "Parsed imgur reference", true);
        D.emptyDiv(col, "h-5");

        // Imgur art

        D.textParagraph(col, this.asset_name + " art on imgur:", "font-bold");
        D.emptyDiv(col, "h-5");
        this.testImgurUrl(col, postslash, postdot);

    }

    updateCheckFileSize(result) {
        console.log("readystate: " + result.readyState);
        console.log("status: " + result.status);
        console.log("response: " + result.responseText.length);
        if (result.status != 200) {
            this.checkFinished("Could not download raw image from Imgur", false);
            return;
        }
        if (result.readyState == 3) {
            var b = result.responseText.length;
            this.checkInProgress(this.file_size_check,
                                 "File Size Less Than 5MB (" + b + " bytes downloaded)")
        } else if (result.readyState == 4) {
            result = (result.responseText.length < (5 * 1024 * 1024));
            this.checkFinished("File Size Less Than 5MB", result);
        }
    }

    checkFileSize(div, url) {
        console.log("check file size: " + url);
        this.checkInProgress(div, "File Size Less Than 5MB (0 bytes downloaded)");
        var checker = this;

        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = (function() {
            checker.updateCheckFileSize(this);
        });
        xhttp.open("GET", url, true);
        xhttp.send();
    }

    doCheck() {
        this.fetchAssetInfo();
    }
}

exports.XchainCheck = XchainCheck;
