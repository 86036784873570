let CARDS = require('../cards.json');
const D = require("./dom.js").DomUtl;
var XchainCheck = require("./xchain_check.js").XchainCheck;


class DogeKvltApp {
    constructor() {
        this.display_div = document.getElementById("display");
        this.sidebar_div = document.getElementById("sidebar");
        //console.log(this.display_div);
        //console.log(CARDS);
        this.n_series = 1;
        this.series_idx = {};

        for (var symbol in CARDS) {
            if (CARDS[symbol]['series'] > this.n_series) {
                this.n_series = CARDS[symbol]['series'];
            }
        }
        for (var i=1; i <= this.n_series; i++) {
            this.series_idx[i] = {};
        }
        this.series_idx['tarot'] = {};
        for (var symbol in CARDS) {
            var info = CARDS[symbol];
            info['symbol'] = symbol;
            this.series_idx[info['series']][info['card']] = info;
        }
        //console.log("idx: " + this.series_idx);
    }

    drawAppUi(pathname) {
        this.drawSidebar();
        if (pathname == "/") {
            this.drawSeriesDisplay(this.display_div, 1, true);
            return;
        }
        //console.log("pathname sliced: " + pathname);
        var upper = pathname.slice(1).toUpperCase()
        if (pathname == "/submissions") {
            this.drawSubmissionDisplay(this.display_div);
        } else if (pathname == "/about") {
            this.drawAboutDisplay(this.display_div);
        } else if (pathname == "/members") {
            this.drawMembersDisplay(this.display_div);
        } else if (pathname == "/tarot") {
            this.drawSeriesDisplay(this.display_div, "tarot", false);
        } else if (pathname.startsWith("/series")) {
            var seriessplit = pathname.split("/series")
            if (seriessplit.length == 1) {
                this.drawNothingHereDisplay(this.display_div);
                return;
            }
            if (! this.isIntString(seriessplit[1])) {
                this.drawNothingHereDisplay(this.display_div);
                return;
            }
            var seriesnum = Number(seriessplit[1]);
            if (seriesnum > this.n_series) {
                this.drawNothingHereDisplay(this.display_div);
                return;
            }
            this.drawSeriesDisplay(this.display_div, seriesnum, false);
        } else if (upper in CARDS) {
            var info = CARDS[upper];
            info['symbol'] = upper;
            //console.log("info: " + JSON.stringify(info));
            this.drawSingleCardDisplay(this.display_div, info);
        } else {
            this.drawNothingHereDisplay(this.display_div);
        }
    }

    //////////////////////////////////////////////////////////////////////////
    // util
    //////////////////////////////////////////////////////////////////////////

    cardsInSeries(series) {
        return Object.keys(this.series_idx[series]).length;
    }

    nextSeries(series) {
        var last_numeric;

        if (series == "tarot") {
            return null;
        }
        last_numeric = Object.keys(this.series_idx).length - 1;
        if (series == last_numeric) {
            return "tarot";
        }
        return series + 1;
    }

    prevSeries(series) {
        var prev_numeric;

        if (series == 1) {
            return null;
        }
        if (series == "tarot") {
            return Object.keys(this.series_idx).length - 1;
        }
        prev_numeric = series - 1;
    }

    isIntString(str) {
        if (typeof str != "string") {
            return false;
        }
        return !isNaN(str) && !isNaN(parseInt(str))
    }

    getNextCard(info) {
        var series = info['series'];
        var card = info['card'];
        var next_series;
        var next_card;
        var cards_in_series;

        cards_in_series = this.cardsInSeries(series);
        if (card == cards_in_series) {
            next_card = 1;
            next_series = this.nextSeries(series);
        } else {
            next_card = card + 1;
            next_series = series;
        }
        if (next_card == null) {
            return null;
        }
        if (next_series == null) {
            return null;
        }
        var idx = this.series_idx[next_series];
        if (! next_card in idx) {
            return null;
        }
        return this.series_idx[next_series][next_card];
    }

    getPrevCard(info) {
        var series = info['series'];
        var card = info['card'];
        var prev_series;
        var prev_card;
        var cards_in_series;

        if (card == 1) {
            prev_series = this.prevSeries(series)
            if (prev_series == null) {
                return null;
            }
            prev_card = this.cardsInSeries(prev_series);
        } else {
            prev_card = card - 1;
            prev_series = series;
        }
        if (prev_series == null) {
            return null;
        }
        if (prev_card == null) {
            return null;
        }
        var idx = this.series_idx[prev_series];
        if (! prev_card in idx) {
            return null;
        }
        return this.series_idx[prev_series][prev_card];
    }


    //////////////////////////////////////////////////////////////////////////
    // Big card display
    //////////////////////////////////////////////////////////////////////////

    drawCardBig(div, info) {
        var d = D.emptyDiv(div, "p-1");
        var extension = info['art_url'].split(".").pop()
        var url = "/art/" + info['symbol'] + "." + extension;

        var i = D.image(d, url, "p-1 megashadow");
        i.setAttribute("alt", info['symbol']);
        i.setAttribute("width", 800);


        var r = D.emptyDiv(d, "flex flex-row justify-center pt-5");
        var p = D.emptyDiv(r, "flex flex-col justify-center rounded border border-red-700 bg-black text-red-600 megashadow w-1/3 py-1");
        D.textParagraph(p, info['symbol'], "flex flex-row justify-center font-bold text-center");
        if (info['series'] == "tarot") {
            D.textParagraph(p, "Tarot" + " Card " + info['card'], "text-center");
        } else {
            D.textParagraph(p, "Series " + info['series'] + " Card " + info['card'], "text-center");
        }
        D.hyperlinkTabOpen(p, "xchain",
            "https://dogeparty.xchain.io/asset/" + info['symbol'], "text-blue-900 font-bold text-center pb-2");
    }

    drawSingleCardDisplay(div, info) {
        history.pushState(null, null, "/" + info['symbol']);
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");

        var brow = D.emptyDiv(col, "flex justify-center");

        var a = document.createElement("a");
        brow.appendChild(a);
        a.setAttribute("href", "/series" + info['series']);
        a.setAttribute("onclick", "return false;");

        var button = D.emptyDiv(a,
                               "flex justify-center hover:bg-gray-500 cursor-pointer megashadow " +
                               "bg-black rounded text-l text-red-600 font-bold py-1 px-1 w-auto");
        button.onclick = (function() {
            this.drawSeriesDisplay(this.display_div, info['series']);
        }).bind(this);
        if (info['series'] == "tarot") {
            D.textParagraph(button, "< Tarot", "text-center px-2");
        } else {
            D.textParagraph(button, "< Series " + info['series'], "text-center px-2");
        }

        var navrow = D.emptyDiv(col, "flex justify-between px-4");
        var prev = this.getPrevCard(info);
        if (prev != null) {
            var a = document.createElement("a");
            navrow.appendChild(a);
            a.setAttribute("href", "/" + prev['symbol']);
            a.setAttribute("onclick", "return false;");
            var prevbutton = D.emptyDiv(a,
                                        "flex justify-center hover:bg-gray-500 cursor-pointer megashadow " +
                                        "bg-black rounded text-l text-red-600 font-bold py-1 px-1 w-auto");
            prevbutton.onclick = (function() {
                this.drawSingleCardDisplay(this.display_div, prev);
            }).bind(this);
            D.textParagraph(prevbutton, "< " + prev['symbol'], "text-center px-2");
        } else {
            D.emptyDiv(navrow);
        }
        var next = this.getNextCard(info);
        if (next != null) {
            var a = document.createElement("a");
            navrow.appendChild(a);
            a.setAttribute("href", "/" + next['symbol']);
            a.setAttribute("onclick", "return false;");
            var nextbutton = D.emptyDiv(a,
                                        "flex justify-center hover:bg-gray-500 cursor-pointer megashadow " +
                                        "bg-black rounded text-l text-red-600 font-bold py-1 px-1 w-auto");
            nextbutton.onclick = (function() {
                this.drawSingleCardDisplay(this.display_div, next);
            }).bind(this);
            D.textParagraph(nextbutton, next['symbol'] + " >", "text-center px-2");
        } else {
            D.emptyDiv(navrow);
        }
        D.emptyDiv(col, "h-5");
        var card_div = D.emptyDiv(col, "flex justify-center");
        this.drawCardBig(card_div, info);
    }

    //////////////////////////////////////////////////////////////////////////
    // series displsy
    //////////////////////////////////////////////////////////////////////////

    drawCardSmall(div, info) {
        var a = document.createElement("a");
        div.appendChild(a);

        a.setAttribute("href", "/" + info['symbol']);
        a.setAttribute("onclick", "return false;");

        var d = D.emptyDiv(a, "rounded hover:bg-red-900 pb-2 cursor-pointer");
        d.onclick = (function() {
            this.drawSingleCardDisplay(this.display_div, info);
        }).bind(this);

        var extension = info['art_url'].split(".").pop()
        var url = "/art/" + info['symbol'] + "." + extension;
        var i = D.image(d, url, "tinyshadow p-2");
        i.setAttribute("alt", info['symbol']);
        i.setAttribute("width", 300);

        var r = D.emptyDiv(d, "flex flex-row justify-center");
        var p = D.emptyDiv(r, "flex flex-col justify-center rounded border border-red-700 bg-black text-red-600 tinyshadow w-1/2 py-1");
        D.textParagraph(p, info['symbol'], "flex flex-row justify-center font-bold text-center");
        var series;
        if (info['series'] == "tarot") {
            series = "Tarot";
        } else {
            series = "Series" + info['series'];
        }
        D.textParagraph(p, series + " Card " + info['card'], "text-center");
    }

    drawSeriesDisplay(div, series, mainpage) {
        if (! mainpage) {
            if (series == "tarot") {
                history.pushState(null, null, "/tarot");
            } else {
                history.pushState(null, null, "/series" + series);
            }
        }
        window.scrollTo(0, 0);
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");

        var title_row = D.emptyDiv(col, "flex justify-center pb-3");
        var title_box = D.emptyDiv(title_row,
            "bg-black rounded border border-red-700 w-32 " +
            "megashadow text-red-600 text-center text-2xl font-bold");
        if (series == "tarot") {
            var title = D.textParagraph(title_box, "Tarot", "p-2");
        } else {
            var title = D.textParagraph(title_box, "Series " + series, "p-2");
        }

        var card_div = D.emptyDiv(col, "flex flex-wrap justify-center");
        for (var i = 1; i <= this.cardsInSeries(series); i++) {
            if (i in this.series_idx[series]) {
                var info = this.series_idx[series][i];
                this.drawCardSmall(card_div, info);
            }
        }

        var next = this.nextSeries(series);
        var prev = this.prevSeries(series);
        var navrow = D.emptyDiv(col, "flex justify-between px-4");
        if (prev != null) {
            var a = document.createElement("a");
            navrow.appendChild(a);
            a.setAttribute("href", "/series" + prev);
            a.setAttribute("onclick", "return false;");
            var prevbutton = D.emptyDiv(a,
                                        "flex justify-center hover:bg-gray-500 cursor-pointer megashadow " +
                                        "bg-black rounded text-l text-red-600 font-bold py-1 px-1 w-auto");
            prevbutton.onclick = (function() {
                this.drawSeriesDisplay(this.display_div, prev, false);
            }).bind(this);
            if (prev == "tarot") {
                D.textParagraph(prevbutton, "< Tarot", "text-center px-2");
            } else {
                D.textParagraph(prevbutton, "< Series " + prev, "text-center px-2");
            }
        } else {
            D.emptyDiv(navrow);
        }
        if (next != null) {
            var a = document.createElement("a");
            navrow.appendChild(a);
            a.setAttribute("href", "/series" + next);
            a.setAttribute("onclick", "return false;");
            var nextbutton = D.emptyDiv(a,
                                        "flex justify-center hover:bg-gray-500 cursor-pointer megashadow " +
                                        "bg-black rounded text-l text-red-600 font-bold py-1 px-1 w-auto");
            nextbutton.onclick = (function() {
                this.drawSeriesDisplay(this.display_div, next, false);
            }).bind(this);
            if (next == "tarot") {
                D.textParagraph(nextbutton, "Tarot " + " >", "text-center px-2");
            } else {
                D.textParagraph(nextbutton, "Series " + next + " >", "text-center px-2");
            }
        } else {
            D.emptyDiv(navrow);
        }
        D.emptyDiv(col, "h-5");
    }

    //////////////////////////////////////////////////////////////////////////
    // about
    //////////////////////////////////////////////////////////////////////////

    drawAboutInfo(div, info) {
        var o = D.emptyDiv(div, "flex justify-center p-4");
        var d = D.emptyDiv(o,
            "bg-black rounded border border-red-700 " +
            "megashadow text-red-600 text-center p-10");
        D.textParagraph(d, "No gods, no masters, only Doge.", "pt-5");
        D.textParagraph(d, "Do what thou wilt shall be the whole of the law.");
        D.emptyDiv(d, "h-10");
        var t = D.emptyDiv(d, "flex justify-center");
        var a = document.createElement("a");
        a.setAttribute("href", "https://t.me/dogekvlt");
        a.setAttribute("target", "_blank");
        D.image(a, "/img/telegram-red.png", "w-1/6");
        D.setClass(a, "flex justify-center");
        D.textParagraph(d, "Telegram:");
        d.appendChild(a);
        D.emptyDiv(d, "h-5");
        D.textParagraph(d, "Tribute:");
        D.hyperlinkTabOpen(d, "D666SuchSacrificeMuchSatan664e6dE2",
            "https://dogeparty.xchain.io/address/" +
            "D666SuchSacrificeMuchSatan664e6dE2", "font-bold");
        D.emptyDiv(d, "h-5");
        D.hyperlinkTabOpen(d, "JSON Metadata", "./cards.json", "font-bold");
    }

    drawAboutDisplay(div) {
        history.pushState(null, null, "/about");
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");

        var title_row = D.emptyDiv(col, "flex justify-center");
        var title_box = D.emptyDiv(title_row,
            "bg-black rounded border border-red-700 w-32 " +
            "megashadow text-red-600 text-center text-3xl font-bold");
        var title = D.textParagraph(title_box, "About", "p-2");

        var card_div = D.emptyDiv(col, "flex justify-center");
        this.drawAboutInfo(card_div);
    }

    //////////////////////////////////////////////////////////////////////////
    // card checker
    //////////////////////////////////////////////////////////////////////////

    postCheckMessage(status_div, msg) {
        D.deleteChildren(status_div);
        D.textParagraph(status_div, msg);
    }

    isAllCaps(str) {
        return str.toUpperCase() == str;
    }

    cardCheck(result_div, check_div, status_div) {
        var asset_name = this.input_div.value;
        //console.log(asset_name);
        if (asset_name.length < 3) {
            this.postCheckMessage(status_div, "Asset name must be at least 3 characters");
            return;
        }
        if (asset_name.length > 12) {
            this.postCheckMessage(status_div, "Asset name can't be more than 12 characters");
            return;
        }
        if (! this.isAllCaps(asset_name)) {
            this.postCheckMessage(status_div, "Asset name must be capital letters. no subassets.");
            return;
        }
        if (asset_name[0] == 'A') {
            this.postCheckMessage(status_div, "Asset must be named (can't start with A)");
            return;
        }
        D.deleteChildren(check_div);
        D.deleteChildren(status_div);

        this.xchain_check = new XchainCheck(this, status_div, result_div, asset_name);
        this.xchain_check.doCheck();
    }

    drawButton(div, button_text, click_func, button_type) {
        var b = D.button(div, click_func, button_type);
        var flex = D.emptyDiv(b, "flex items-center justify-around");
        var text = D.textSpan(flex, button_text);
        b.inner_text_span = text;
        return b;
    }

    drawCheckButton(div, paste_func) {
       this.drawButton(div, "Check", paste_func,
                       "px-2 py-2 bg-gray-800 hover:bg-gray-700 border cursor-pointer " +
                       "border-gray-800 rounded");
    }

    drawAssetNameInput(div) {
        var flex = D.emptyDiv(div, "flex flex-col justify-center");
        var check_div = D.emptyDiv(flex,
                               "flex justify-center items-center " +
                               "py-2 m-2 rounded-2xl");
        var i = D.emptyInput(check_div, "w-auto rounded px-2");
       ///console.log(i);
        i.setAttribute("placeholder", "Dogeparty Asset");
        i.setAttribute("size", "12");
        i.setAttribute("maxlength", "12");
        D.emptyDiv(check_div, "w-2");
        var status_div = D.emptyDiv(div, "p3");
        var result_div = D.emptyDiv(div, "p3");
        this.input_div = i;
        i.addEventListener("keyup",
            (function() {
                if (event.keyCode === 13) {
                    this.cardCheck(result_div, check_div, status_div);
                }
            }).bind(this));
        this.drawCheckButton(check_div, (function() {
            this.cardCheck(result_div, check_div, status_div);
        }).bind(this));
    }

    drawResetCheckOkButton() {
        var button_parent = D.emptyDiv(this.card_checker);
        var button = D.emptyDiv(button_parent,
            "flex justify-center hover:bg-gray-400 cursor-pointer " +
            "bg-gray-600 rounded text-l font-bold py-1 px-1");
        D.textParagraph(button, "OK");
        button.onclick = (function() {
            D.deleteChildren(this.card_checker);
            D.textParagraph(this.card_checker, "Check Asset", "p-2 text-3xl font-bold");
            this.drawAssetNameInput(this.card_checker);
        }).bind(this);

    }

    drawCardChecker(div) {
        var o = D.emptyDiv(div, "p-4");
        this.card_checker = D.emptyDiv(o,
            "bg-black rounded border border-red-700 " +
            "megashadow text-red-600 text-center p-4");
        D.textParagraph(this.card_checker, "Check Asset", "p-2 text-3xl font-bold");
        this.drawAssetNameInput(this.card_checker);
    }

    //////////////////////////////////////////////////////////////////////////
    // submission rules
    //////////////////////////////////////////////////////////////////////////

    drawSubmissionInfo(div, info) {
        var o = D.emptyDiv(div, "flex justify-center p-4");
        var b = D.emptyDiv(o, "flex justify-center bg-black rounded border border-red-700 " +
            "megashadow text-red-600 text-center p-4 px-5");
        var d = D.emptyDiv(b, "flex flex-col justify-center text-center w-1/2")
        D.textParagraph(d, "OPEN FOR SUBMISSIONS", "text-2xl font-bold text-green-500 py-2");
        D.emptyDiv(d, "h-5");
        D.textParagraph(d, "Submission rules:", "text-2xl font-bold pb-2");
        D.textParagraph(d, "1. Don't be a pussy and don't make pussy art.");
        D.textParagraph(d, "2. Must have at least something to do with Doge.");
        D.textParagraph(d, "3. Be original, no stealing.");
        D.textParagraph(d, "4. No porn, gore or gross stuff.");
        D.textParagraph(d, "5. Named asset; no subassets");
        D.textParagraph(d, "6. Token must be locked");
        D.textParagraph(d, "7. Token must be non-divisible");
        D.textParagraph(d, "8. Token must have minimum 100, maximum 125 " +
                        "issuance. This is a cult, not a religion.");
        D.textParagraph(d, "9. Put the art on imgur and reference it in the " +
                        "token description. See KVLT card description for example.");
        D.textParagraph(d, "10. 400px wide and 560px tall OR 800px wide and 1120px tall");
        D.textParagraph(d, "11. png, jpg, or gif");
        D.textParagraph(d, "12. max 5 MB file size");
        D.emptyDiv(d, "h-5");
        D.textParagraph(d, "Submitting:", "text-2xl font-bold pb-2");
        D.textParagraph(d, "1) Get the above rules 100% correct.");
        D.textParagraph(d, "2) Use the automatic checker tool below to make sure.")
        D.textParagraph(d, "3) Send 1 card to each Kvlt member.");
        D.textParagraph(d, "4) Be patient.");
        D.textParagraph(d, "5) If your card is not accepted, it could be because it " +
                           "doesn't meet the rules or because it's lame or doesn't belong here. ");
        D.textParagraph(d, "6) Nothing personal. Get good. Try again.");
        D.emptyDiv(d, "h-5");
    }

    drawSubmissionDisplay(div) {
        history.pushState(null, null, "/submissions");
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");
        var title_row = D.emptyDiv(col, "flex justify-center");
        var title_box = D.emptyDiv(title_row,
            "bg-black rounded border border-red-700 w-64 " +
            "megashadow text-red-600 text-center text-3xl font-bold");
        var title = D.textParagraph(title_box, "Submissions", "p-2");
        var card_div = D.emptyDiv(col, "flex justify-center");
        this.drawSubmissionInfo(card_div);
        var checker_div = D.emptyDiv(col, "flex justify-center");
        this.drawCardChecker(checker_div);
    }

    //////////////////////////////////////////////////////////////////////////
    // member lookup
    //////////////////////////////////////////////////////////////////////////

    memberLookup(member_result_div) {
        D.deleteChildren(member_result_div);
        D.textParagraph(member_result_div, "Fetching from XChain...");
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    var data = JSON.parse(this.responseText)['data'];
                    var members = "";
                    function shuffle(array) {
                        array.sort(() => Math.random() - 0.5);
                    }
                    shuffle(data);
                    D.deleteChildren(member_result_div);
                    D.textParagraph(member_result_div,
                                    data.length + " Kvlt Members:");
                    D.emptyDiv(member_result_div, "h-5");
                    var p = D.emptyPre(member_result_div);

                    for (var i = 0; i < data.length; i++) {
                        members = members + data[i]['address'] + "\n";
                    }
                    p.innerHTML = members;
                } else {
                    //console.log("resp: " + this.responseText);
                    D.deleteChildren(member_result_div);
                    D.textParagraph(member_result_div,
                        "Whoops! error from Xchain " + this.responseText);

                }
            }
        }
        xhttp.open("GET", "https://dogeparty.xchain.io/api/holders/KVLT", true);
        //xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send();
    }
    //////////////////////////////////////////////////////////////////////////
    // submission rules
    //////////////////////////////////////////////////////////////////////////

    drawMembersInfo(div, info) {
        var o = D.emptyDiv(div, "flex justify-center p-4");
        var d = D.emptyDiv(o,
            "bg-black rounded border border-red-700 " +
            "megashadow text-red-600 text-center px-10 py-5 w-5/6");
        D.textParagraph(d, "1) Kvlt members hold the KVLT card");
        D.textParagraph(d, "2) There are only 100 KVLT cards in circulation.");
        D.textParagraph(d,
                        "3) KVLT cards in escrow for a DEX order or dispenser" +
                        " contract don't count.");
        D.textParagraph(d,
                        "4) An address holding more than one KVLT card only " +
                        "counts once.");
        D.textParagraph(d,
                        "5) Addresses presented in random order.");
        D.emptyDiv(d, "h-8");

        var button_parent = D.emptyDiv(d);
        var button = D.emptyDiv(button_parent,
            "flex justify-center hover:bg-gray-400 cursor-pointer " +
            "bg-gray-600 rounded text-l font-bold py-1 px-1");
        D.textParagraph(button, "Fetch Current Member List");
        var member_result_div = D.emptyDiv(d);
        button.onclick = (function() {
            D.deleteChildren(button_parent);
            this.memberLookup(member_result_div);
        }).bind(this);
    }

    drawMembersDisplay(div) {
        history.pushState(null, null, "/members");
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");

        var title_row = D.emptyDiv(col, "flex justify-center");
        var title_box = D.emptyDiv(title_row,
            "bg-black rounded border border-red-700 w-52 " +
            "megashadow text-red-600 text-center text-3xl font-bold");
        var title = D.textParagraph(title_box, "Members", "p-2");

        var card_div = D.emptyDiv(col, "flex justify-center");
        this.drawMembersInfo(card_div);
    }

    //////////////////////////////////////////////////////////////////////////
    // nothing here
    //////////////////////////////////////////////////////////////////////////

    drawNothingHereInfo(div, info) {
        var o = D.emptyDiv(div, "flex justify-center p-4");
        var d = D.emptyDiv(o,
            "bg-black rounded border border-red-700 " +
            "megashadow text-red-600 text-center px-10 py-5 w-5/6");
        D.textParagraph(d, "There doesn't seem to be anything here....");
    }

    drawNothingHereDisplay(div) {
        D.deleteChildren(div);
        var col = D.emptyDiv(div, "flex flex-col pt-5");

        var title_row = D.emptyDiv(col, "flex justify-center");
        var title_box = D.emptyDiv(title_row,
            "bg-black rounded border border-red-700 w-52 " +
            "megashadow text-red-600 text-center text-3xl font-bold");
        var title = D.textParagraph(title_box, "NOT FOUND", "p-2");
        var card_div = D.emptyDiv(col, "flex justify-center");
        this.drawNothingHereInfo(card_div);
    }

    //////////////////////////////////////////////////////////////////////////
    // sidebar
    //////////////////////////////////////////////////////////////////////////

    drawSidebarLink(div, text, func, path) {
        var a = document.createElement("a");
        div.appendChild(a);
        a.setAttribute("href", path);
        a.setAttribute("onclick", "return false;");
        var button = D.emptyDiv(a,
                               "flex justify-center hover:bg-gray-500 cursor-pointer " +
                               "bg-black rounded text-l font-bold py-1 px-1");
        button.onclick = func;
        D.textParagraph(button, text);

    }

    drawSeriesLink(div, series) {
        var a = document.createElement("a");
        div.appendChild(a);
        a.setAttribute("href", "/series" + series);
        a.setAttribute("onclick", "return false;");
        var button = D.emptyDiv(a,
                               "flex justify-center hover:bg-gray-500 cursor-pointer " +
                               "bg-black rounded text-l font-bold py-1 px-1");
        button.onclick = (function() {
            this.drawSeriesDisplay(this.display_div, series, false);
        }).bind(this);
        D.textParagraph(button, "Series " + series);
    }

    drawTarotLink(div) {
        var a = document.createElement("a");
        div.appendChild(a);
        a.setAttribute("href", "/seriestarot");
        a.setAttribute("onclick", "return false;");
        var button = D.emptyDiv(a,
                               "flex justify-center hover:bg-gray-500 cursor-pointer " +
                               "bg-black rounded text-l font-bold py-1 px-1");
        button.onclick = (function() {
            this.drawSeriesDisplay(this.display_div, "tarot", false);
        }).bind(this);
        D.textParagraph(button, "Tarot");
    }

    drawSidebar() {
        D.deleteChildren(this.sidebar_div);
        this.drawSidebarLink(this.sidebar_div, "About",
            (function() {
                this.drawAboutDisplay(this.display_div);
            }).bind(this), "/about");
        this.drawSidebarLink(this.sidebar_div, "Submissions",
            (function() {
                this.drawSubmissionDisplay(this.display_div);
            }).bind(this), "/submissions");
        this.drawSidebarLink(this.sidebar_div, "Members",
            (function() {
                this.drawMembersDisplay(this.display_div);
            }).bind(this), "/members");

        for (var i = 1; i <= this.n_series; i++) {
            this.drawSeriesLink(this.sidebar_div, i);
        }
        this.drawTarotLink(this.sidebar_div);
    }
}


window.app = new DogeKvltApp();

function drawFirstUi() {
    if (location.pathname) {
        console.log(location.pathname);
    }
    console.log(window.location.pathname);
    window.app.drawAppUi(window.location.pathname)

}
window.addEventListener("load", drawFirstUi());

window.addEventListener("popstate", function(e) {
    var nav = location.pathname.slice(1);
    console.log("nav: " + nav);
    if (nav == "") {
        window.app.drawSeriesDisplay(window.app.display_div, 1, true);
    } else if (nav == "submissions") {
        window.app.drawSubmissionDisplay(window.app.display_div);
    } else if (nav == "members") {
        window.app.drawMembersDisplay(window.app.display_div);
    } else if (nav == "about") {
        window.app.drawAboutDisplay(window.app.display_div);
    } else if (nav == "tarot") {
        window.app.drawSeriesDisplay(window.app.display_div, "tarot", false);
    } else if (nav.startsWith("series")) {
        var seriessplit = nav.split("series");
        //console.log("seriessplit: " + seriessplit);
        if (seriessplit.length == 1) {
            window.app.drawNothingHereDisplay(window.app.display_div);
            return;
        }
        if (! window.app.isIntString(seriessplit[1])) {
            window.app.drawNothingHereDisplay(window.app.display_div);
            return;
        }
        var seriesnum = Number(seriessplit[1]);
        if (seriesnum > window.app.n_series) {
            window.app.drawNothingHereDisplay(window.app.display_div);
            return;
        }
        window.app.drawSeriesDisplay(window.app.display_div, seriesnum, false);
    } else if (nav in CARDS) {
        var info = CARDS[nav];
        window.app.drawSingleCardDisplay(window.app.display_div, info);
    }
});

