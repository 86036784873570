module.exports={
 "BAPH": {
  "art_url": "https://dogekvlt.com/art/BAPH.jpg",
  "card": 33,
  "series": 2
 },
 "BATHODOGE": {
  "art_url": "https://dogekvlt.com/art/BATHODOGE.gif",
  "card": 13,
  "series": 1
 },
 "BEARDENDOGE": {
  "art_url": "https://dogekvlt.com/art/BEARDENDOGE.jpg",
  "card": 3,
  "series": 1
 },
 "BLACKNWHITE": {
  "art_url": "https://dogekvlt.com/art/BLACKNWHITE.png",
  "card": 19,
  "series": 1
 },
 "BOHEMIANDOGE": {
  "art_url": "https://dogekvlt.com/art/BOHEMIANDOGE.png",
  "card": 21,
  "series": 1
 },
 "BONDOGE": {
  "art_url": "https://dogekvlt.com/art/BONDOGE.jpg",
  "card": 42,
  "series": 1
 },
 "BTCKILLER": {
  "art_url": "https://dogekvlt.com/art/BTCKILLER.jpg",
  "card": 26,
  "series": 1
 },
 "CANINIVALEUS": {
  "art_url": "https://dogekvlt.com/art/CANINIVALEUS.png",
  "card": 25,
  "series": 2
 },
 "CANONDEATH": {
  "art_url": "https://dogekvlt.com/art/CANONDEATH.png",
  "card": 12,
  "series": 1
 },
 "DANASDILEMNA": {
  "art_url": "https://dogekvlt.com/art/DANASDILEMNA.jpg",
  "card": 14,
  "series": 1
 },
 "DARKLORDOGE": {
  "art_url": "https://dogekvlt.com/art/DARKLORDOGE.jpg",
  "card": 39,
  "series": 1
 },
 "DEADTOME": {
  "art_url": "https://dogekvlt.com/art/DEADTOME.jpg",
  "card": 50,
  "series": 1
 },
 "DEATHSONG": {
  "art_url": "https://dogekvlt.com/art/DEATHSONG.jpg",
  "card": 23,
  "series": 2
 },
 "DEMONDOGE": {
  "art_url": "https://dogekvlt.com/art/DEMONDOGE.jpg",
  "card": 1,
  "series": 2
 },
 "DENTISTDOGE": {
  "art_url": "https://dogekvlt.com/art/DENTISTDOGE.jpg",
  "card": 44,
  "series": 1
 },
 "DEVOURERDOGE": {
  "art_url": "https://dogekvlt.com/art/DEVOURERDOGE.jpg",
  "card": 15,
  "series": 1
 },
 "DGPENNYWISE": {
  "art_url": "https://dogekvlt.com/art/DGPENNYWISE.gif",
  "card": 16,
  "series": 1
 },
 "DIMDOGES": {
  "art_url": "https://dogekvlt.com/art/DIMDOGES.png",
  "card": 11,
  "series": 2
 },
 "DISTURBED": {
  "art_url": "https://dogekvlt.com/art/DISTURBED.jpg",
  "card": 2,
  "series": 1
 },
 "DOGEBLOOD": {
  "art_url": "https://dogekvlt.com/art/DOGEBLOOD.png",
  "card": 11,
  "series": 1
 },
 "DOGEBUTCHER": {
  "art_url": "https://dogekvlt.com/art/DOGEBUTCHER.png",
  "card": 27,
  "series": 2
 },
 "DOGECONQUEST": {
  "art_url": "https://dogekvlt.com/art/DOGECONQUEST.png",
  "card": 17,
  "series": 2
 },
 "DOGECULT": {
  "art_url": "https://dogekvlt.com/art/DOGECULT.png",
  "card": 35,
  "series": 1
 },
 "DOGEDEVIL": {
  "art_url": "https://dogekvlt.com/art/DOGEDEVIL.png",
  "card": 34,
  "series": 1
 },
 "DOGEEATSDOGE": {
  "art_url": "https://dogekvlt.com/art/DOGEEATSDOGE.jpg",
  "card": 8,
  "series": 2
 },
 "DOGEEVIL": {
  "art_url": "https://dogekvlt.com/art/DOGEEVIL.png",
  "card": 24,
  "series": 1
 },
 "DOGEFLOYD": {
  "art_url": "https://dogekvlt.com/art/DOGEFLOYD.png",
  "card": 41,
  "series": 1
 },
 "DOGEHADES": {
  "art_url": "https://dogekvlt.com/art/DOGEHADES.gif",
  "card": 8,
  "series": 1
 },
 "DOGEILL": {
  "art_url": "https://dogekvlt.com/art/DOGEILL.png",
  "card": 3,
  "series": 2
 },
 "DOGEKILL": {
  "art_url": "https://dogekvlt.com/art/DOGEKILL.png",
  "card": 32,
  "series": 2
 },
 "DOGELETTER": {
  "art_url": "https://dogekvlt.com/art/DOGELETTER.png",
  "card": 17,
  "series": 1
 },
 "DOGELUNAS": {
  "art_url": "https://dogekvlt.com/art/DOGELUNAS.png",
  "card": 43,
  "series": 2
 },
 "DOGELYCAN": {
  "art_url": "https://dogekvlt.com/art/DOGELYCAN.jpg",
  "card": 22,
  "series": 2
 },
 "DOGEONYX": {
  "art_url": "https://dogekvlt.com/art/DOGEONYX.png",
  "card": 23,
  "series": 1
 },
 "DOGERAM": {
  "art_url": "https://dogekvlt.com/art/DOGERAM.jpg",
  "card": 28,
  "series": 1
 },
 "DOGERAMBE": {
  "art_url": "https://dogekvlt.com/art/DOGERAMBE.png",
  "card": 40,
  "series": 1
 },
 "DOGERUG": {
  "art_url": "https://dogekvlt.com/art/DOGERUG.png",
  "card": 5,
  "series": 1
 },
 "DOGESECRET": {
  "art_url": "https://dogekvlt.com/art/DOGESECRET.png",
  "card": 27,
  "series": 1
 },
 "DOGESEER": {
  "art_url": "https://dogekvlt.com/art/DOGESEER.gif",
  "card": 38,
  "series": 1
 },
 "DOGESKULLS": {
  "art_url": "https://dogekvlt.com/art/DOGESKULLS.png",
  "card": 49,
  "series": 1
 },
 "DOGESTEINED": {
  "art_url": "https://dogekvlt.com/art/DOGESTEINED.gif",
  "card": 37,
  "series": 1
 },
 "DOGESTONE": {
  "art_url": "https://dogekvlt.com/art/DOGESTONE.png",
  "card": 15,
  "series": 2
 },
 "DOGETV": {
  "art_url": "https://dogekvlt.com/art/DOGETV.png",
  "card": 26,
  "series": 2
 },
 "DOGEVICTIM": {
  "art_url": "https://dogekvlt.com/art/DOGEVICTIM.jpg",
  "card": 36,
  "series": 1
 },
 "DOGVOTION": {
  "art_url": "https://dogekvlt.com/art/DOGVOTION.gif",
  "card": 4,
  "series": 1
 },
 "DWARFDOGES": {
  "art_url": "https://dogekvlt.com/art/DWARFDOGES.png",
  "card": 19,
  "series": 2
 },
 "EMBERDOGE": {
  "art_url": "https://dogekvlt.com/art/EMBERDOGE.png",
  "card": 37,
  "series": 2
 },
 "EXORCISM": {
  "art_url": "https://dogekvlt.com/art/EXORCISM.png",
  "card": 36,
  "series": 2
 },
 "FEEDME": {
  "art_url": "https://dogekvlt.com/art/FEEDME.gif",
  "card": 7,
  "series": 1
 },
 "FRIDAYNIGHT": {
  "art_url": "https://dogekvlt.com/art/FRIDAYNIGHT.png",
  "card": 2,
  "series": 2
 },
 "FRIGHTENDOGE": {
  "art_url": "https://dogekvlt.com/art/FRIGHTENDOGE.jpg",
  "card": 16,
  "series": 2
 },
 "FUCKSCIENCE": {
  "art_url": "https://dogekvlt.com/art/FUCKSCIENCE.png",
  "card": 44,
  "series": 2
 },
 "GATESSTEAK": {
  "art_url": "https://dogekvlt.com/art/GATESSTEAK.jpg",
  "card": 32,
  "series": 1
 },
 "GHOSTDOGELIA": {
  "art_url": "https://dogekvlt.com/art/GHOSTDOGELIA.jpg",
  "card": 10,
  "series": 2
 },
 "HAPPYCLOUDS": {
  "art_url": "https://dogekvlt.com/art/HAPPYCLOUDS.jpg",
  "card": 10,
  "series": 1
 },
 "HAUNTEDOGE": {
  "art_url": "https://dogekvlt.com/art/HAUNTEDOGE.png",
  "card": 12,
  "series": 2
 },
 "HEAVENSDOGE": {
  "art_url": "https://dogekvlt.com/art/HEAVENSDOGE.png",
  "card": 31,
  "series": 2
 },
 "HEAVENSGATE": {
  "art_url": "https://dogekvlt.com/art/HEAVENSGATE.png",
  "card": 28,
  "series": 2
 },
 "HORRORMCDOGE": {
  "art_url": "https://dogekvlt.com/art/HORRORMCDOGE.png",
  "card": 43,
  "series": 1
 },
 "ISADOGEH": {
  "art_url": "https://dogekvlt.com/art/ISADOGEH.png",
  "card": 46,
  "series": 1
 },
 "KITSUNEDOGE": {
  "art_url": "https://dogekvlt.com/art/KITSUNEDOGE.gif",
  "card": 38,
  "series": 2
 },
 "KOLLEKTIVE": {
  "art_url": "https://dogekvlt.com/art/KOLLEKTIVE.png",
  "card": 25,
  "series": 1
 },
 "KONSPIRACY": {
  "art_url": "https://dogekvlt.com/art/KONSPIRACY.png",
  "card": 30,
  "series": 2
 },
 "KVLT": {
  "art_url": "https://dogekvlt.com/art/KVLT.png",
  "card": 1,
  "series": 1
 },
 "KVLTALCHEMY": {
  "art_url": "https://dogekvlt.com/art/KVLTALCHEMY.png",
  "card": 39,
  "series": 2
 },
 "KVLTCHARIOT": {
  "art_url": "https://dogekvlt.com/art/KVLTCHARIOT.png",
  "card": 72,
  "series": "tarot"
 },
 "KVLTCOINKN": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINKN.png",
  "card": 12,
  "series": "tarot"
 },
 "KVLTCOINP": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINP.png",
  "card": 11,
  "series": "tarot"
 },
 "KVLTCOINQ": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINQ.png",
  "card": 13,
  "series": "tarot"
 },
 "KVLTCOINSI": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSI.png",
  "card": 1,
  "series": "tarot"
 },
 "KVLTCOINSII": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSII.png",
  "card": 2,
  "series": "tarot"
 },
 "KVLTCOINSIII": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSIII.png",
  "card": 3,
  "series": "tarot"
 },
 "KVLTCOINSIIX": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSIIX.png",
  "card": 8,
  "series": "tarot"
 },
 "KVLTCOINSIV": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSIV.png",
  "card": 4,
  "series": "tarot"
 },
 "KVLTCOINSIX": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSIX.png",
  "card": 9,
  "series": "tarot"
 },
 "KVLTCOINSK": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSK.png",
  "card": 14,
  "series": "tarot"
 },
 "KVLTCOINSV": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSV.png",
  "card": 5,
  "series": "tarot"
 },
 "KVLTCOINSVI": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSVI.png",
  "card": 6,
  "series": "tarot"
 },
 "KVLTCOINSVII": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSVII.png",
  "card": 7,
  "series": "tarot"
 },
 "KVLTCOINSX": {
  "art_url": "https://dogekvlt.com/art/KVLTCOINSX.png",
  "card": 10,
  "series": "tarot"
 },
 "KVLTCUPI": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPI.png",
  "card": 43,
  "series": "tarot"
 },
 "KVLTCUPII": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPII.png",
  "card": 44,
  "series": "tarot"
 },
 "KVLTCUPIII": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPIII.png",
  "card": 45,
  "series": "tarot"
 },
 "KVLTCUPIIX": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPIIX.png",
  "card": 50,
  "series": "tarot"
 },
 "KVLTCUPIV": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPIV.png",
  "card": 46,
  "series": "tarot"
 },
 "KVLTCUPIX": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPIX.png",
  "card": 51,
  "series": "tarot"
 },
 "KVLTCUPK": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPK.png",
  "card": 56,
  "series": "tarot"
 },
 "KVLTCUPKN": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPKN.png",
  "card": 54,
  "series": "tarot"
 },
 "KVLTCUPP": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPP.png",
  "card": 53,
  "series": "tarot"
 },
 "KVLTCUPQ": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPQ.png",
  "card": 55,
  "series": "tarot"
 },
 "KVLTCUPV": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPV.png",
  "card": 47,
  "series": "tarot"
 },
 "KVLTCUPVI": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPVI.png",
  "card": 48,
  "series": "tarot"
 },
 "KVLTCUPVII": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPVII.png",
  "card": 49,
  "series": "tarot"
 },
 "KVLTCUPX": {
  "art_url": "https://dogekvlt.com/art/KVLTCUPX.png",
  "card": 52,
  "series": "tarot"
 },
 "KVLTDEATH": {
  "art_url": "https://dogekvlt.com/art/KVLTDEATH.png",
  "card": 57,
  "series": "tarot"
 },
 "KVLTDEVIL": {
  "art_url": "https://dogekvlt.com/art/KVLTDEVIL.png",
  "card": 63,
  "series": "tarot"
 },
 "KVLTEMPEROR": {
  "art_url": "https://dogekvlt.com/art/KVLTEMPEROR.png",
  "card": 58,
  "series": "tarot"
 },
 "KVLTEMPRESS": {
  "art_url": "https://dogekvlt.com/art/KVLTEMPRESS.png",
  "card": 59,
  "series": "tarot"
 },
 "KVLTFOOL": {
  "art_url": "https://dogekvlt.com/art/KVLTFOOL.png",
  "card": 73,
  "series": "tarot"
 },
 "KVLTFORCE": {
  "art_url": "https://dogekvlt.com/art/KVLTFORCE.png",
  "card": 60,
  "series": "tarot"
 },
 "KVLTHANGED": {
  "art_url": "https://dogekvlt.com/art/KVLTHANGED.png",
  "card": 74,
  "series": "tarot"
 },
 "KVLTHERMIT": {
  "art_url": "https://dogekvlt.com/art/KVLTHERMIT.png",
  "card": 75,
  "series": "tarot"
 },
 "KVLTINSIDE": {
  "art_url": "https://dogekvlt.com/art/KVLTINSIDE.jpg",
  "card": 41,
  "series": 2
 },
 "KVLTIST": {
  "art_url": "https://dogekvlt.com/art/KVLTIST.png",
  "card": 18,
  "series": 2
 },
 "KVLTJUDGMENT": {
  "art_url": "https://dogekvlt.com/art/KVLTJUDGMENT.png",
  "card": 61,
  "series": "tarot"
 },
 "KVLTJUSTICE": {
  "art_url": "https://dogekvlt.com/art/KVLTJUSTICE.png",
  "card": 62,
  "series": "tarot"
 },
 "KVLTLOVERS": {
  "art_url": "https://dogekvlt.com/art/KVLTLOVERS.png",
  "card": 76,
  "series": "tarot"
 },
 "KVLTMAGICIAN": {
  "art_url": "https://dogekvlt.com/art/KVLTMAGICIAN.png",
  "card": 69,
  "series": "tarot"
 },
 "KVLTMOON": {
  "art_url": "https://dogekvlt.com/art/KVLTMOON.png",
  "card": 66,
  "series": "tarot"
 },
 "KVLTPOPE": {
  "art_url": "https://dogekvlt.com/art/KVLTPOPE.png",
  "card": 77,
  "series": "tarot"
 },
 "KVLTPRIESTES": {
  "art_url": "https://dogekvlt.com/art/KVLTPRIESTES.png",
  "card": 70,
  "series": "tarot"
 },
 "KVLTQUEEN": {
  "art_url": "https://dogekvlt.com/art/KVLTQUEEN.gif",
  "card": 29,
  "series": 1
 },
 "KVLTSTAR": {
  "art_url": "https://dogekvlt.com/art/KVLTSTAR.png",
  "card": 64,
  "series": "tarot"
 },
 "KVLTSUN": {
  "art_url": "https://dogekvlt.com/art/KVLTSUN.png",
  "card": 65,
  "series": "tarot"
 },
 "KVLTSWORDI": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDI.png",
  "card": 15,
  "series": "tarot"
 },
 "KVLTSWORDIII": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDIII.png",
  "card": 17,
  "series": "tarot"
 },
 "KVLTSWORDIIX": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDIIX.png",
  "card": 22,
  "series": "tarot"
 },
 "KVLTSWORDIV": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDIV.png",
  "card": 18,
  "series": "tarot"
 },
 "KVLTSWORDIX": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDIX.png",
  "card": 23,
  "series": "tarot"
 },
 "KVLTSWORDK": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDK.png",
  "card": 28,
  "series": "tarot"
 },
 "KVLTSWORDKN": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDKN.png",
  "card": 26,
  "series": "tarot"
 },
 "KVLTSWORDLOL": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDLOL.png",
  "card": 16,
  "series": "tarot"
 },
 "KVLTSWORDP": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDP.png",
  "card": 25,
  "series": "tarot"
 },
 "KVLTSWORDQ": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDQ.png",
  "card": 27,
  "series": "tarot"
 },
 "KVLTSWORDV": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDV.png",
  "card": 19,
  "series": "tarot"
 },
 "KVLTSWORDVI": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDVI.png",
  "card": 20,
  "series": "tarot"
 },
 "KVLTSWORDVII": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDVII.png",
  "card": 21,
  "series": "tarot"
 },
 "KVLTSWORDX": {
  "art_url": "https://dogekvlt.com/art/KVLTSWORDX.png",
  "card": 24,
  "series": "tarot"
 },
 "KVLTTEMPER": {
  "art_url": "https://dogekvlt.com/art/KVLTTEMPER.png",
  "card": 71,
  "series": "tarot"
 },
 "KVLTTOWER": {
  "art_url": "https://dogekvlt.com/art/KVLTTOWER.png",
  "card": 68,
  "series": "tarot"
 },
 "KVLTWANDI": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDI.png",
  "card": 29,
  "series": "tarot"
 },
 "KVLTWANDII": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDII.png",
  "card": 30,
  "series": "tarot"
 },
 "KVLTWANDIII": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDIII.png",
  "card": 31,
  "series": "tarot"
 },
 "KVLTWANDIIX": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDIIX.png",
  "card": 36,
  "series": "tarot"
 },
 "KVLTWANDIV": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDIV.png",
  "card": 32,
  "series": "tarot"
 },
 "KVLTWANDIX": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDIX.png",
  "card": 37,
  "series": "tarot"
 },
 "KVLTWANDK": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDK.png",
  "card": 42,
  "series": "tarot"
 },
 "KVLTWANDKN": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDKN.png",
  "card": 40,
  "series": "tarot"
 },
 "KVLTWANDP": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDP.png",
  "card": 39,
  "series": "tarot"
 },
 "KVLTWANDQ": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDQ.png",
  "card": 41,
  "series": "tarot"
 },
 "KVLTWANDV": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDV.png",
  "card": 33,
  "series": "tarot"
 },
 "KVLTWANDVI": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDVI.png",
  "card": 34,
  "series": "tarot"
 },
 "KVLTWANDVII": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDVII.png",
  "card": 35,
  "series": "tarot"
 },
 "KVLTWANDX": {
  "art_url": "https://dogekvlt.com/art/KVLTWANDX.png",
  "card": 38,
  "series": "tarot"
 },
 "KVLTWHEEL": {
  "art_url": "https://dogekvlt.com/art/KVLTWHEEL.png",
  "card": 78,
  "series": "tarot"
 },
 "KVLTWORLD": {
  "art_url": "https://dogekvlt.com/art/KVLTWORLD.png",
  "card": 67,
  "series": "tarot"
 },
 "MANSONDOGE": {
  "art_url": "https://dogekvlt.com/art/MANSONDOGE.gif",
  "card": 9,
  "series": 2
 },
 "MAXWOLF": {
  "art_url": "https://dogekvlt.com/art/MAXWOLF.png",
  "card": 6,
  "series": 1
 },
 "MEDOICRITY": {
  "art_url": "https://dogekvlt.com/art/MEDOICRITY.png",
  "card": 45,
  "series": 2
 },
 "MERZDOGE": {
  "art_url": "https://dogekvlt.com/art/MERZDOGE.png",
  "card": 5,
  "series": 2
 },
 "MOONCHILD": {
  "art_url": "https://dogekvlt.com/art/MOONCHILD.jpg",
  "card": 20,
  "series": 2
 },
 "MUFFBITCH": {
  "art_url": "https://dogekvlt.com/art/MUFFBITCH.jpg",
  "card": 20,
  "series": 1
 },
 "NADOGIUS": {
  "art_url": "https://dogekvlt.com/art/NADOGIUS.jpg",
  "card": 33,
  "series": 1
 },
 "PEPEINSIDE": {
  "art_url": "https://dogekvlt.com/art/PEPEINSIDE.jpg",
  "card": 46,
  "series": 2
 },
 "PURPLEDREAM": {
  "art_url": "https://dogekvlt.com/art/PURPLEDREAM.jpg",
  "card": 48,
  "series": 1
 },
 "RAISEHELL": {
  "art_url": "https://dogekvlt.com/art/RAISEHELL.gif",
  "card": 21,
  "series": 2
 },
 "REDHOOD": {
  "art_url": "https://dogekvlt.com/art/REDHOOD.gif",
  "card": 4,
  "series": 2
 },
 "SACRIFICE": {
  "art_url": "https://dogekvlt.com/art/SACRIFICE.jpg",
  "card": 24,
  "series": 2
 },
 "SAFARIDOGE": {
  "art_url": "https://dogekvlt.com/art/SAFARIDOGE.jpg",
  "card": 6,
  "series": 2
 },
 "SEAWOLFDOGE": {
  "art_url": "https://dogekvlt.com/art/SEAWOLFDOGE.gif",
  "card": 42,
  "series": 2
 },
 "SHAMANDOGE": {
  "art_url": "https://dogekvlt.com/art/SHAMANDOGE.png",
  "card": 7,
  "series": 2
 },
 "SMOKINGDOGE": {
  "art_url": "https://dogekvlt.com/art/SMOKINGDOGE.jpg",
  "card": 13,
  "series": 2
 },
 "SSDOGE": {
  "art_url": "https://dogekvlt.com/art/SSDOGE.png",
  "card": 22,
  "series": 1
 },
 "STAGDOGE": {
  "art_url": "https://dogekvlt.com/art/STAGDOGE.jpg",
  "card": 30,
  "series": 1
 },
 "STROKEME": {
  "art_url": "https://dogekvlt.com/art/STROKEME.gif",
  "card": 18,
  "series": 1
 },
 "SUBLIMIDOGE": {
  "art_url": "https://dogekvlt.com/art/SUBLIMIDOGE.png",
  "card": 31,
  "series": 1
 },
 "SUMMONED": {
  "art_url": "https://dogekvlt.com/art/SUMMONED.jpg",
  "card": 29,
  "series": 2
 },
 "SWINGDOGE": {
  "art_url": "https://dogekvlt.com/art/SWINGDOGE.jpg",
  "card": 45,
  "series": 1
 },
 "THESHEDDER": {
  "art_url": "https://dogekvlt.com/art/THESHEDDER.png",
  "card": 47,
  "series": 1
 },
 "UMBRELLADOGE": {
  "art_url": "https://dogekvlt.com/art/UMBRELLADOGE.jpeg",
  "card": 40,
  "series": 2
 },
 "WARNINGONE": {
  "art_url": "https://dogekvlt.com/art/WARNINGONE.gif",
  "card": 14,
  "series": 2
 },
 "WEREWOLVEN": {
  "art_url": "https://dogekvlt.com/art/WEREWOLVEN.jpg",
  "card": 35,
  "series": 2
 },
 "WWTWO": {
  "art_url": "https://dogekvlt.com/art/WWTWO.jpg",
  "card": 34,
  "series": 2
 },
 "YOUAREANIMAL": {
  "art_url": "https://dogekvlt.com/art/YOUAREANIMAL.png",
  "card": 9,
  "series": 1
 }
}